import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { getUserMetaKey } from 'src/hooks/domain/queries/useGetUserMeta';
import { useCustomSnackbar } from 'src/hooks/useCustomSnackbar';
import { ErrorCodes, getGraphQLClient, getTokens, UserMetaRole } from '../../../lib';

const mutation = gql`
  mutation CreateUserMeta($payload: UserMetaCreationPayload!) {
    createUserMeta(payload: $payload) {
      userId
      role
    }
  }
`;

export const useCreateUserMeta = () => {
  const queryClient = useQueryClient();
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (payload: { role: UserMetaRole }) => {
      const tokens = await getTokens();
      const client = await getGraphQLClient(tokens);

      return (
        await client.request<{ CreateUserMeta?: { userId: string; role: string } }, { payload: { role: string } }>(
          mutation,
          { payload }
        )
      ).CreateUserMeta;
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(getUserMetaKey);
      },
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.CreateUserMeta, eventId });
      },
    }
  );
};
