import { createAxiosInstance } from 'src/lib/auth/createAxiosInstance';
import { ACCOUNTS_API_TOKEN_HEADER, config } from 'src/lib/constants';
import { getExistingToken, getAccountsApiToken } from 'src/lib/auth/okta';

export const filesApiClient = createAxiosInstance({
  baseUrl: `${config.formsApiUrl}/api`,
  getAsyncHeaders: async () => {
    const [token, accountsApiToken] = await Promise.all([getExistingToken(), getAccountsApiToken()]);
    // TODO: add ds3 token once we verify we can pull it from okta instead of files-api
    return {
      Authorization: `Bearer ${token}`,
      [ACCOUNTS_API_TOKEN_HEADER]: accountsApiToken,
    };
  },
  defaultHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
