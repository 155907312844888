import axios from 'axios';
import { TokenDomain } from '@skyslope/auth-js';
import jwt_decode from 'jwt-decode';
import { config } from '../constants';
import { postUserProfiles } from '../api/user';
import { heapUserDataInit } from '../heapInit';
import { AuthStore } from './store';
import { userManager } from './userManager';

export interface OktaPasswordPolicy {
  description: string;
  minLength?: number;
}

export interface TokenStore {
  formsToken: string;
  sklosuresToken: string;
  ds3Token: string;
  accountsApiToken: string;
  marketplaceApiToken: string;
}

export const authClient = userManager._oktaAuth.oktaAuth;

export const buildAuthStoreFromAuthState = async (): Promise<AuthStore> => {
  const authState = await userManager.getAuthState();
  const idClaims = authState.idToken?.claims;
  const accessClaims = authState.accessToken?.claims;
  const formsUserCheckComplete: boolean = !!sessionStorage.getItem('formsUserCheckComplete') ?? false;

  if (idClaims && accessClaims) {
    heapUserDataInit(
      {
        userId: idClaims.sub,
        Email: idClaims.email!,
        SubscriberId: idClaims.subscriber_id!,
        UserType: idClaims.prime_contact_type!,
      },
      accessClaims.groups?.includes('/forms/users/standalone')
    );
  }

  return {
    loggedIn: authState.isAuthenticated,
    userId: idClaims?.sub ?? null,
    accountAction: null,
    shownPopup: false,
    userInfo: {
      firstName: idClaims?.first_name ?? '',
      lastName: idClaims?.last_name ?? '',
      email: idClaims?.email ?? '',
    },
    formsUserCheckComplete: formsUserCheckComplete,
  };
};

export const login = async (username: string, password: string) => {
  localStorage.removeItem('com.skyslope.logout');
  const transaction = await authClient.signInWithCredentials({ username, password });
  if (transaction.status !== 'SUCCESS') {
    throw new Error('Invalid Login');
  }
  const res = await authClient.token.getWithoutPrompt({ sessionToken: transaction.sessionToken });
  authClient.tokenManager.setTokens(res.tokens);
  heapUserDataInit(
    {
      userId: res.tokens!.idToken!.claims!.sub,
      Email: res.tokens!.idToken!.claims!.email!,
      SubscriberId: res.tokens.idToken?.claims.subscriber_id,
      UserType: res.tokens.idToken?.claims.prime_contact_type,
    },
    res.tokens!.accessToken!.claims!.groups.includes('/forms/users/standalone')
  );
  return res.tokens;
};

export const getRegistrationForm = async () => {
  const formInfo = await axios({ method: 'GET', url: `${config.auth.okta.baseUrl}/api/v1/registration/form` });
  const passwordPolicies = formInfo.data.profileSchema.properties.password.allOf
    .map((pp: OktaPasswordPolicy) => pp.description)
    .filter(Boolean);
  return { policyId: formInfo.data.policyId, passwordPolicies };
};

export interface OktaPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
}

export const register = async (policyId: string, oktaPayload: OktaPayload) => {
  // deconstruct phoneNumber out because the okta payload doesn't expect it.
  const { phoneNumber, ...data } = oktaPayload;
  await axios({
    method: 'POST',
    url: `${config.auth.okta.baseUrl}/api/v1/registration/${policyId}/register`,
    data: { userProfile: data },
  }).catch((e) => {
    throw new Error(e.response?.data?.errorCauses?.[0]?.errorSummary || 'Registration failed, please try again.');
  });

  const loginUser = await login(oktaPayload.email, oktaPayload.password);

  // Give Okta some time to update the tokens before trying to call to Forms to create a profile
  setTimeout(async () => {
    await postUserProfiles(oktaPayload.firstName, oktaPayload.lastName, oktaPayload.email, phoneNumber);
  }, 0);
  return loginUser;
};

export const loginWithRedirect = (originalUri?: string, loginHint?: string) => {
  userManager.login({
    idp: userManager.idps.primeAuth,
    loginHint: loginHint,
    originalUri: originalUri,
  });
};

export const getApiToken = async (tokenDomain = TokenDomain.Forms, force = false): Promise<string> => {
  const scopesPerDomain = {
    [TokenDomain.Accounts]: ['openid', 'profile', 'email', 'address', 'phone'],
    [TokenDomain.Marketplace]: ['openid', 'profile', 'email', 'com.skyslope.marketplace.api'],
    [TokenDomain.Sklosures]: [
      'openid',
      'profile',
      'email',
      'address',
      'phone',
      'com.skyslope.groups',
      'com.skyslope.prime.subscriber',
    ],
    [TokenDomain.DigiSign]: [
      'com.skyslope.digisign.userid',
      'com.skyslope.forms.accountid',
      'com.skyslope.groups',
      'com.skyslope.prime.subscriber',
      'openid',
      'email',
    ],
  };

  const scopes = tokenDomain && scopesPerDomain[tokenDomain];
  return await userManager.getAccessToken(tokenDomain, scopes, force);
};

export const getExistingToken = async () => getApiToken();

const getCognitoToken = async () => {
  const token = await getExistingToken();
  const result = await axios.get(`${config.breezeServiceUrl}/auth/digisign/cognito`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
export const getDS3ClientToken = async (data: { firstName: string; lastName: string; email: string }) => {
  const token = await getCognitoToken();
  const result = await axios({
    url: `${config.ds3UserServiceUrl}/client`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return result.data.access_token;
};

export const getDS3Token = async (force?: boolean) => getApiToken(TokenDomain.DigiSign, force);

export const getSklosuresToken = async () => getApiToken(TokenDomain.Sklosures);

/**
 * Handles ds3 tokens. First gets the ds3 token from storage. If the token does not have the
 * required user_id field for ds3, call their user service to provision a user, then refetch the token
 * from the server.
 * @returns the up to date ds3 token
 */
export const handleDs3Token = async () => {
  const token = await getDS3Token(false);
  const decoded = jwt_decode(token) as unknown as any;
  if (!decoded.user_id) {
    await getDS3ClientToken({
      firstName: decoded.first_name,
      lastName: decoded.last_name,
      email: decoded.sub,
    });
    return await getDS3Token(true);
  }
  return token;
};
export const getAccountsApiToken = async () => getApiToken(TokenDomain.Accounts);
export const getMarketplaceApiToken = async () => getApiToken(TokenDomain.Marketplace);

export const getTokens = async (
  domains = [TokenDomain.Forms, TokenDomain.Accounts, TokenDomain.Sklosures, TokenDomain.DigiSign]
) => {
  const store: TokenStore = {
    formsToken: '',
    sklosuresToken: '',
    ds3Token: '',
    accountsApiToken: '',
    marketplaceApiToken: '',
  };
  const domainToKey = {
    [TokenDomain.Forms]: 'formsToken',
    [TokenDomain.Sklosures]: 'sklosuresToken',
    [TokenDomain.DigiSign]: 'ds3Token',
    [TokenDomain.Accounts]: 'accountsApiToken',
    [TokenDomain.Marketplace]: 'marketplaceApiToken',
  };

  await Promise.all(
    domains.map(async (domain) => {
      const token = await getApiToken(domain);
      if (!token) {
        throw new Error(`Tried to fetch a ${domain} token before a session was established`);
      }
      store[domainToKey[domain]] = token;
    })
  );

  return store;
};
