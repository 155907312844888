import { useAuth, useUserClaims } from '@skyslope/auth-react';

export const useIsLoginProcessComplete = (): boolean => {
  const {
    authState: { isAuthenticated },
  } = useAuth();
  const { isLoading: isLoadingClaims } = useUserClaims();

  return isAuthenticated && !isLoadingClaims;
};
