import { useMutation, UseMutationOptions } from 'react-query';
import { gql } from 'graphql-request';
import { getGraphQLClient, getTokens } from 'src/lib';

const mutationQuery = gql`
  mutation CreateEscrowOfficerProfile($payload: CreateEscrowOfficerProfilePayload) {
    createEscrowOfficerProfile(payload: $payload) {
      userId
      ownerId
    }
  }
`;

export interface CreateEscrowOfficerProfilePayload {
  onBehalfOf?: string;
}

export const useCreateEscrowOfficerProfile = (
  options: UseMutationOptions<unknown, unknown, CreateEscrowOfficerProfilePayload> = {}
) =>
  useMutation(async (variables: CreateEscrowOfficerProfilePayload) => {
    const client = getGraphQLClient(await getTokens());
    await client.request(mutationQuery, { payload: variables });
  }, options);
