import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import { ErrorCodes, getGraphQLClient, getTokens } from '../../../lib';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';
import { FilePackage, PackageStatusType } from '../../../pages/FilePackages/types';
import { useCustomSnackbar } from '../../useCustomSnackbar';
import { useSetUxPackageStatus } from './useSetUxPackageStatus';

export interface SendDisclosuresForSignaturePayload {
  packageId: string;
  subject: string;
  body: string;
}

const mutationQuery = gql`
  mutation sendDisclosuresForSignature($packageId: String!, $subject: String!, $body: String!, $onBehalfOf: String) {
    sendDisclosuresEnvelope(packageId: $packageId, subject: $subject, body: $body, onBehalfOf: $onBehalfOf)
  }
`;

export const sendDisclosuresEnvelopeMutationKey = 'send-disclosures-envelope-mutation';

export const useSendDisclosuresEnvelopeMutation = (fileId: number) => {
  const queryClient = useQueryClient();
  const setPackageUxStatus = useSetUxPackageStatus(fileId);
  const { addErrorSnackbar, addSuccessSnackbar } = useCustomSnackbar();
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  return useMutation(
    async (payload: SendDisclosuresForSignaturePayload) => {
      const client = getGraphQLClient(await getTokens());
      return (
        await client.request<
          { sendDisclosuresForSignature: boolean },
          { packageId: string; subject: string; body: string; onBehalfOf?: string }
        >(mutationQuery, {
          packageId: payload.packageId,
          subject: payload.subject,
          body: payload.body,
          onBehalfOf: selectedAgent?.userId,
        })
      ).sendDisclosuresForSignature;
    },
    {
      mutationKey: sendDisclosuresEnvelopeMutationKey,
      onMutate: async (payload: SendDisclosuresForSignaturePayload) => ({
        previousPackages: await setPackageUxStatus(
          payload.packageId,
          PackageStatusType.uxLoading,
          'Sending for signatures...'
        ),
      }),
      onSuccess: () => {
        addSuccessSnackbar('Your disclosures have been sent for signatures.');
      },
      async onError(e, data, context: { previousPackages?: FilePackage[] }) {
        console.error('An error occurred while sending disclosures envelope');
        const eventId = Sentry.captureException(e);

        addErrorSnackbar({
          message: 'Your disclosures failed to send, please try again.',
          errorCode: ErrorCodes.SendDisclosuresEnvelope,
          eventId,
        });
        const packagesQueryKey = [getPackagesByFileIdKey, fileId];
        if (context?.previousPackages) {
          queryClient.setQueryData(packagesQueryKey, context.previousPackages);
        }
      },
      onSettled: () => {
        const packagesQueryKey = [getPackagesByFileIdKey, fileId];
        queryClient.invalidateQueries(packagesQueryKey);
      },
    }
  );
};
