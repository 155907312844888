import { UseMutationOptions, useMutation } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getTokens } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';
import { DownloadPackageResponse } from './useDownloadPackageMerged';

interface DownloadPackageVariables {
  packageId: string;
}

export const downloadZipPackageKey = 'download-package-zip';

const query = gql`
  query downloadPackage($packageId: String) {
    packagePDFsBase64(packageId: $packageId) {
      filename
      content
    }
  }
`;

export const useDownloadPackageZip = (options: UseMutationOptions<unknown, unknown, DownloadPackageVariables> = {}) => {
  const snackbarController = useCustomSnackbar();

  return useMutation(
    async (variables: DownloadPackageVariables) => {
      const client = getGraphQLClient(await getTokens());
      return (await client.request<{ packagePDFsBase64: DownloadPackageResponse[] }>(query, variables))
        .packagePDFsBase64;
    },
    {
      ...options,
      onMutate() {
        snackbarController.addInfoSnackbar('Downloading your files');
      },
      onError(e, variables, context) {
        console.error(e);
        const eventId = Sentry.captureException(e);

        if (options.onError) {
          options.onError(e, variables, context);
        } else {
          snackbarController.addErrorSnackbar({ errorCode: ErrorCodes.DownloadPackageZip, eventId });
        }
      },
    }
  );
};
