import { useMutation, UseMutationOptions } from 'react-query';
import { gql } from 'graphql-request';
import { TokenDomain } from '@skyslope/auth-js';
import { getGraphQLClient, EscrowOfficer, RequestTitleReportPayload, Address, getTokens } from 'src/lib';

const mutationQuery = gql`
  mutation updateEscrowDetails($payload: UpdatePackageEscrowDetailsPayload) {
    preOpenEscrow(payload: $payload)
  }
`;

export type UpdatePackageEscrowDetailsPayload = {
  fileId?: number;
  property?: Address;
  escrowOfficer?: EscrowOfficer;
  onBehalfOf?: string;
  emailPayload?: RequestTitleReportPayload;
};

export const usePreOpenEscrow = (
  options: UseMutationOptions<unknown, unknown, UpdatePackageEscrowDetailsPayload> = {}
) =>
  useMutation(async (variables: UpdatePackageEscrowDetailsPayload) => {
    const client = getGraphQLClient(
      await getTokens([TokenDomain.Forms, TokenDomain.Accounts, TokenDomain.Marketplace])
    );
    await client.request(mutationQuery, { payload: variables });
  }, options);
