import { useEffect, useState } from 'react';
import { useUserClaims } from '@skyslope/auth-react';
import { formatChameleonUserRole } from 'src/lib';
import { useGetBreezeRole, useGetPackageCountByStatus, useIsLoginProcessComplete } from 'src/hooks/domain/queries';
import { chameleon } from '../../lib/chameleon/chameleon';
import { config } from '../../lib/constants';

export const useSetupChameleon = (): void => {
  const [isChameleonInitialized, setIsChameleonInitialized] = useState(false);
  const isLoggedIn = useIsLoginProcessComplete();
  const { claims } = useUserClaims();
  const { data: breezeRole } = useGetBreezeRole({ enabled: isLoggedIn });
  const { data: packageData } = useGetPackageCountByStatus({ enabled: isLoggedIn });
  const isBreezeUser = breezeRole ? 1 : 0;

  useEffect(() => {
    chameleon.initialize(config.chameleonToken);
    setIsChameleonInitialized(true);
  }, []);

  useEffect(() => {
    if (claims?.sub && breezeRole && isChameleonInitialized) {
      chameleon.setupUser(
        claims.sub,
        claims.email,
        formatChameleonUserRole(breezeRole),
        claims.first_name,
        claims.last_name,
        claims.prime_contact_type,
        claims.subscriber_id,
        packageData?.disclosuresSentCount,
        packageData?.disclosuresCompletedCount,
        packageData?.avidCount,
        packageData?.avidCompletedCount,
        isBreezeUser
      );
    }
  }, [isChameleonInitialized, claims, breezeRole, packageData, isBreezeUser]);
};

/* Chameleon script */
