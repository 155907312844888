import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { useCustomSnackbar } from '../../../hooks';
import { ErrorCodes, getGraphQLClient, getTokens } from '../../../lib';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';
import { getDashboardPackagesByFileIdKey } from '../queries/useGetDashboardPackages';

const mutationQuery = gql`
  mutation CancelPackageInterviews($packageId: String!, $specificInterviewIds: [Int]!) {
    cancelPackageInterviews(packageId: $packageId, specificInterviewIds: $specificInterviewIds)
  }
`;

export interface CancelPackageInterviewsVariables {
  packageId: string;
  specificInterviewIds?: number[];
}

export const useCancelInterviews = () => {
  const queryClient = useQueryClient();
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (variables: CancelPackageInterviewsVariables) => {
      const client = getGraphQLClient(await getTokens());
      await client.request(mutationQuery, variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([getPackagesByFileIdKey]);
        queryClient.invalidateQueries([getDashboardPackagesByFileIdKey]);
      },
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.CancelInterview, eventId });
      },
    }
  );
};
