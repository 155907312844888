import axios from 'axios';
import { getExistingToken } from '../auth/okta';
import { config } from '../constants';

export interface LibraryAuthProfile {
  id: number;
  externalId: string;
  source: string;
}

export interface LibraryResult {
  id: number;
  name: string;
  authProfiles: LibraryAuthProfile[];
}
export const getLibraries = async (): Promise<LibraryResult[]> => {
  const token = await getExistingToken();
  const result = await axios.get(`${config.formsApiUrl}/library/api/libraries`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      'api-version': 2.0,
    },
  });
  return result.data.result;
};
