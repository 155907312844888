import { useUserClaims } from '@skyslope/auth-react';
import { useCreateUserMeta } from 'src/hooks';
import { UserMetaRole } from 'src/lib';
import { BreezeUserMeta, useGetUserMeta } from 'src/hooks/domain/queries/useGetUserMeta';

enum SkyslopeContactTypes {
  Agent = 'Agent',
  Auditor = 'Auditor',
  Broker = 'Broker',
  LimitedTC = 'Limited TC',
}

const mapping: { [type: string]: UserMetaRole } = {
  [SkyslopeContactTypes.Agent]: UserMetaRole.AGENT,
  [SkyslopeContactTypes.Auditor]: UserMetaRole.AUDITOR,
  [SkyslopeContactTypes.Broker]: UserMetaRole.BROKER,
  [SkyslopeContactTypes.LimitedTC]: UserMetaRole.TC,
};

export const useImportSkyslopeRole = () => {
  const { claims: clams } = useUserClaims();
  const { mutateAsync: createUserMeta } = useCreateUserMeta();

  useGetUserMeta<BreezeUserMeta | undefined>({
    enabled: !!clams,
    onSuccess: async (data) => {
      if (!(data == null && clams.prime_contact_type != null)) {
        return;
      }
      const breezeRole = mapping[clams.prime_contact_type];
      if (breezeRole == null) {
        console.error(`encountered unknown prime_contact_type ${clams.prime_contact_type}, skipping import`);
        return;
      }
      await createUserMeta({ role: breezeRole ?? UserMetaRole.AGENT });
    },
  });
};
