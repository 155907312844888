import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useCustomSnackbar } from 'src/hooks/useCustomSnackbar';
import { getAccountsApiToken, getExistingToken, ACCOUNTS_API_TOKEN_HEADER, config, Contact, ErrorCodes } from 'src/lib';
import { getFilesByIdQueryKey } from '../queries/useGetFileById';

type DeleteContactsPayload = {
  fileId: number;
  contacts: Contact[];
};

export const useDeleteContacts = () => {
  const queryClient = useQueryClient();
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (payload: DeleteContactsPayload) => {
      const token = await getExistingToken();
      return await Promise.all(
        payload.contacts.map(async (contact) => {
          const accountsApiToken = await getAccountsApiToken();

          return axios({
            method: 'DELETE',
            url: `${config.formsApiUrl}/api/files/${payload.fileId}/contacts/${contact.id}`,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              [ACCOUNTS_API_TOKEN_HEADER]: accountsApiToken,
            },
          });
        })
      );
    },
    {
      onSuccess(result, variables) {
        queryClient.invalidateQueries([getFilesByIdQueryKey, variables.fileId]);
      },
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.DeleteContacts, eventId });
      },
    }
  );
};
