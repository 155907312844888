import React from 'react';
import { useQueryClient, useMutation, UseMutationOptions } from 'react-query';
import {
  useAuthStore,
  getUserCarVerify,
  AuthType,
  updateUserAuthProfiles,
  usStates,
  GetEnumKeyByValue,
  getUserNwmlsVerify,
  getUserOrefVerify,
  NRDS_AUTH_PROFILE_TYPES,
  getUserNrdsVerify,
  VerifyNrdsRequest,
  VerifyNwmlsRequest,
  VerifyOrefRequest,
  VerifyCarRequest,
  getAuthTypeInfo,
} from 'src/lib';
import { getLibraries } from 'src/lib/api/libraries';
import { useUpdateUserLibraries } from '../domain/mutations/useUpdateUserLibraries';
import { useUpdateUserProfile } from '../domain/mutations/useUpdateUserProfile';
import { useGetUserProfile } from '../domain/queries/useGetUserProfile';
import { getUserProfileMetaKey } from '../domain/queries/useGetUserProfileMeta';

export type VerifyCredsRequest = Partial<VerifyCarRequest | VerifyNrdsRequest | VerifyNwmlsRequest | VerifyOrefRequest>;

export interface VerifyCredsPayload {
  authType: AuthType;
  request: VerifyCredsRequest;
}

export const useVerifyAndSaveCreds = (options: UseMutationOptions<void, string, VerifyCredsPayload>) => {
  const authStore = useAuthStore();
  const userId = authStore.userId as string;
  const queryClient = useQueryClient();
  const { data: userProfile } = useGetUserProfile();
  const { mutateAsync: updateUser } = useUpdateUserProfile();
  const { mutateAsync: updateUserLibraries } = useUpdateUserLibraries();

  return useMutation<void, string | React.ReactNode, VerifyCredsPayload>(
    async ({ authType, request }) => {
      if (userProfile == null) {
        // This shouldn't happen unless the user is on a terrible network, worst
        // case the click submit again
        return;
      }

      const { region, libraryName, libraryIds } = getAuthTypeInfo(authType)!;
      let isVerified;
      try {
        switch (authType) {
          case AuthType.CaNrds:
            isVerified = (await getUserCarVerify(request as VerifyCarRequest)).isVerified;
            break;
          case AuthType.NWMLS:
            isVerified = (await getUserNwmlsVerify(request as VerifyNwmlsRequest)).isVerified;
            break;
          case AuthType.Oref:
            const orefMembershipResponse = await getUserOrefVerify(request as VerifyOrefRequest);
            isVerified = orefMembershipResponse.isVerified && !orefMembershipResponse.isExpired;
            break;
          default:
            if (NRDS_AUTH_PROFILE_TYPES[authType]) {
              const nrdsResponse = await getUserNrdsVerify(request as VerifyNrdsRequest);
              isVerified = nrdsResponse.isVerified;

              // Find the library from Forms to extract the externalId.
              const library = (await getLibraries()).find((l) => libraryIds.includes(l.id));
              const libraryAuthProfile = library?.authProfiles.find((p) => p.source === authType);
              const externalId = libraryAuthProfile?.externalId;

              // A NRDS externalId indicates what libraries a user has access to.
              // Check these 3 fields from NRDS to verify if this user is allowed
              // to send a disclosure from this library.
              if (
                !(
                  !!externalId &&
                  (nrdsResponse.memberData.memberPrimaryAssociationId === parseInt(externalId) ||
                    nrdsResponse.memberData.memberPrimaryStateAssociationId === parseInt(externalId) ||
                    nrdsResponse.memberData.memberSecondaryAssociationIds.includes(parseInt(externalId)))
                )
              ) {
                isVerified = false;
              }
            } else {
              throw `Sorry, ${authType} is not yet supported in Breeze.`;
            }
            break;
        }
      } catch (e) {
        throw 'Sorry, something went wrong on our end. Please try again or contact us.';
      }

      if (isVerified) {
        try {
          if (!userProfile.authProfiles?.some((profile) => profile.type === authType)) {
            const authData = { profiles: [{ type: authType, profile: request, userId }] };
            await updateUserAuthProfiles(authData, userId);
          }

          const localRegion = GetEnumKeyByValue(usStates, region);
          if (!userProfile.regions?.some((region) => region.country === 'US' && region.region === localRegion)) {
            const updatedRegions = [...userProfile.regions];
            updatedRegions.push({
              country: 'US',
              region: localRegion,
            });
            await updateUser({ regions: updatedRegions });
          }

          updateUserLibraries(libraryIds);
        } catch (err) {
          throw (
            <>
              Uh oh. Looks like these {libraryName} credentials are already in use. Please check your login and try
              again. If you need some help,{' '}
              <button onClick={() => (window as any)?.zE?.activate?.()}>click here</button>.
            </>
          );
        }
      } else {
        throw `Sorry, this account is not verified to use ${libraryName}`;
      }
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        queryClient.invalidateQueries(getUserProfileMetaKey);
        if (variables.authType === AuthType.CaNrds) {
          authStore.update({ shownPopup: !authStore.shownPopup });
        }
        options.onSuccess?.(data, variables, context);
      },
    }
  );
};
