import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { gql } from 'graphql-request';
import { TokenDomain } from '@skyslope/auth-js';
import { EscrowOfficer, getGraphQLClient, getTokens, Pagination } from 'src/lib';

const query = gql`
  query getEscrowOfficerList($searchQuery: String, $onBehalfOf: String, $address: String, $offset: Int) {
    getEscrowOfficerList(
      searchQuery: $searchQuery
      onBehalfOf: $onBehalfOf
      address: $address
      pageSize: 6
      offset: $offset
    ) {
      escrowOfficers {
        fullName
        firstName
        lastName
        email
        phoneNumber
        brand
        brandLogo
        city
        state
        image
      }
      nextOffset
      prevOffset
    }
  }
`;

interface QueryResult {
  getEscrowOfficerList: GetEscrowOfficerListQueryResult;
}

export interface GetEscrowOfficerListQueryResult extends Pagination {
  escrowOfficers: EscrowOfficer[];
}

export interface EscrowOfficerListOptions {
  searchQuery?: string;
  onBehalfOf?: string;
  address?: string;
}

export const getEscrowOfficerListKey = 'escrow-officer-list';

export const useGetEscrowOfficerList = (
  args: EscrowOfficerListOptions,
  options: UseInfiniteQueryOptions<GetEscrowOfficerListQueryResult> = {}
) => {
  const compoundKey = [{ ...args, getEscrowOfficerListKey }];
  return useInfiniteQuery(
    compoundKey,
    async ({ pageParam: offset = 0 }) => {
      const client = getGraphQLClient(await getTokens([TokenDomain.Forms, TokenDomain.Marketplace]));
      return client.request<QueryResult>(query, { ...args, offset }).then((result) => result.getEscrowOfficerList);
    },
    {
      ...options,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
    }
  );
};
