import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { TokenDomain } from '@skyslope/auth-js';
import { EscrowOfficer, getGraphQLClient, getTokens } from 'src/lib';

const query = gql`
  query getEscrowOfficerProfile($onBehalfOf: String) {
    getEscrowOfficerProfile(onBehalfOf: $onBehalfOf) {
      recentlyUsedOfficers {
        fullName
        firstName
        lastName
        email
        phoneNumber
        brand
        image
      }
      defaultOfficer {
        fullName
        firstName
        lastName
        email
        phoneNumber
        brand
        image
      }
      ownerId
    }
  }
`;

interface QueryResult {
  getEscrowOfficerProfile: {
    recentlyUsedOfficers: EscrowOfficer[];
    defaultOfficer: EscrowOfficer;
    ownerId: string;
  };
}

export interface GetEscrowOfficerProfileOptions {
  onBehalfOf?: string;
}

export const getEscrowOfficerProfileKey = 'get-escrow-officer-profile';

export const useGetEscrowOfficerProfile = (args: GetEscrowOfficerProfileOptions) =>
  useQuery(getEscrowOfficerProfileKey, async () => {
    const client = getGraphQLClient(await getTokens([TokenDomain.Forms]));
    const result = await client.request<QueryResult>(query, {
      onBehalfOf: args.onBehalfOf,
    });
    return result.getEscrowOfficerProfile;
  });
