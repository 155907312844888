import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { getGraphQLClient, getTokens, WithOnBehalfOf } from '../../../lib';
import { getUserProfileKey } from '../../../hooks/domain/queries/useGetUserProfile';
import { AvidWorkflowData, AvidPackageDTO } from '../types';
import { getAvidPackageKey } from './useGetAvidPackage';

type UpdateAvidPayload = { packageId: string } & Partial<AvidWorkflowData>;

const mutationQuery = gql`
  mutation UpdateAvidPackage($payload: UpdateAvidPackagePayload) {
    updateAvidPackage(payload: $payload) {
      packageId
    }
  }
`;

export const useUpdateAvidPackage = () => {
  const queryClient = useQueryClient();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  return useMutation(
    async (payload: UpdateAvidPayload) => {
      const client = getGraphQLClient(await getTokens());
      return (
        await client.request<{ updateAvidPackage: AvidPackageDTO }, { payload: WithOnBehalfOf<UpdateAvidPayload> }>(
          mutationQuery,
          {
            payload: {
              ...payload,
              onBehalfOf: selectedAgent?.userId,
            },
          }
        )
      ).updateAvidPackage;
    },
    {
      onMutate: async (data) => {
        const { packageId, ...workflowData } = data;
        const queryKey = [getAvidPackageKey, packageId];
        await queryClient.cancelQueries(queryKey);
        const previousPackageData = queryClient.getQueryData<AvidPackageDTO>(queryKey);

        if (previousPackageData) {
          queryClient.setQueryData<AvidPackageDTO>(queryKey, {
            ...previousPackageData,
            workflowData: {
              ...previousPackageData.workflowData,
              ...workflowData,
              propertyType: previousPackageData.workflowData.propertyType,
            },
          });
        }

        return { previousPackageData };
      },
      onError: async (err, data, context: { previousPackageData?: AvidPackageDTO }) => {
        if (context?.previousPackageData) {
          queryClient.setQueryData(getUserProfileKey, context.previousPackageData);
        }
      },
      onSettled: (data, error, variables) => {
        queryClient.invalidateQueries([getAvidPackageKey, variables.packageId]);
      },
    }
  );
};
