import { GraphQLClient } from 'graphql-request';
import {
  ACCOUNTS_API_TOKEN_HEADER,
  config,
  DS3_API_TOKEN_HEADER,
  MARKETPLACE_API_TOKEN_HEADER,
  SKLOSURES_API_TOKEN_HEADER,
  TokenStore,
} from 'src/lib';

export const getGraphQLHeaders = (tokens?: TokenStore) => ({
  headers: {
    Authorization: `Bearer ${tokens?.formsToken ?? ''}`,
    [SKLOSURES_API_TOKEN_HEADER]: tokens?.sklosuresToken ?? '',
    [DS3_API_TOKEN_HEADER]: tokens?.ds3Token ?? '',
    [ACCOUNTS_API_TOKEN_HEADER]: tokens?.accountsApiToken ?? '',
    [MARKETPLACE_API_TOKEN_HEADER]: tokens?.marketplaceApiToken ?? '',
  },
});

export const getGraphQLClient = (tokens?: TokenStore): GraphQLClient => {
  const graphQLHeaders = getGraphQLHeaders(tokens);
  const graphQLUrl = `${config.breezeServiceUrl}/gql`;
  return new GraphQLClient(graphQLUrl, graphQLHeaders);
};
