import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import { ErrorCodes, getGraphQLClient, getTokens, WithOnBehalfOf } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';
import { FilePackage, PackageStatusType } from '../../../pages/FilePackages/types';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';
import { useSetUxPackageStatus } from './useSetUxPackageStatus';

export interface UseSendFormsPayload {
  packageId: string;
  subject: string;
  body: string;
}

export const sendFormsMutationKey = 'send-forms-mutation-key';

const mutationQuery = gql`
  mutation sendForms($packageId: String!, $subject: String!, $body: String!) {
    didSend: sendForms(packageId: $packageId, subject: $subject, body: $body)
  }
`;

export const useSendForms = (fileId: number) => {
  const queryClient = useQueryClient();
  const setPackageUxStatus = useSetUxPackageStatus(fileId);
  const { addErrorSnackbar, addSuccessSnackbar } = useCustomSnackbar();
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  return useMutation(
    async (payload: UseSendFormsPayload) => {
      const client = getGraphQLClient(await getTokens());
      return (
        await client.request<{ didSend: boolean }, WithOnBehalfOf<UseSendFormsPayload>>(mutationQuery, {
          ...payload,
          onBehalfOf: selectedAgent?.userId,
        })
      ).didSend;
    },
    {
      mutationKey: sendFormsMutationKey,
      onMutate: async (payload: UseSendFormsPayload) => ({
        previousPackages: await setPackageUxStatus(
          payload.packageId,
          PackageStatusType.uxLoading,
          `Sending for signatures...`
        ),
      }),
      onSuccess: () => {
        addSuccessSnackbar('Your envelope have been sent for signatures.');
      },
      onError: async (e, data, context: { previousPackages?: FilePackage[] }) => {
        console.error('An error occurred while sending forms');
        const eventId = Sentry.captureException(e);

        addErrorSnackbar({
          message: 'Your envelope failed to send, please try again.',
          errorCode: ErrorCodes.SendForms,
          eventId,
        });
        const packagesQueryKey = [getPackagesByFileIdKey, fileId];
        if (context?.previousPackages) {
          queryClient.setQueryData(packagesQueryKey, context.previousPackages);
        }
      },
      onSettled: () => {
        const packagesQueryKey = [getPackagesByFileIdKey, fileId];
        queryClient.invalidateQueries(packagesQueryKey);
      },
    }
  );
};
