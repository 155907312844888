import { findLastIndex } from 'lodash';
import stateCounties from './usCounties.json';

export const appEnvironments = {
  INTEG: 'integ',
  STAGING: 'staging',
  PROD: 'prod',
};

export const contactActions = {
  NEEDSTOSIGN: 'NeedsToSign',
  RECEIVESCOPY: 'ReceivesCopy',
  NOACTION: 'NoAction',
};

export const SKLOSURES_API_TOKEN_HEADER = 'x-sklosures-authorization';
export const DS3_API_TOKEN_HEADER = 'x-ds3-authorization';
export const ACCOUNTS_API_TOKEN_HEADER = 'x-accounts-authorization';
export const MARKETPLACE_API_TOKEN_HEADER = 'x-marketplace-authorization';

export enum contactTypes {
  BUYER = 'Buyer',
  SELLER = 'Seller',
  TENANT = 'Tenant',
  LANDLORD = 'Landlord',
  OCCUPANT = 'OCCUPANT',
  OTHER = 'Other',
  CHILD = 'Child',
  BUYERAGENT = 'BuyerAgent',
  SELLERAGENT = 'SellerAgent',
}

// This should be kept upto date with files-ui
// https://bitbucket.org/skyslope/files-ui/src/master/src/store/types.ts
export enum AuthType {
  AzNrds = 'Nrds',
  PRIME = 'Prime',
  TxNrds = 'TxNrds',
  NWMLS = 'Nwmls',
  Oref = 'Oref',
  None = 'None',
  CaNrds = 'CaNrds',
  MdNrds = 'MdNrds',
  OhNrds = 'OhNrds',
  InNrds = 'InNrds',
  MaNrds = 'MaNrds',
  PaNrds = 'PaNrds',
  NSBARNrds = 'NSBARNrds',
  GCAARNrds = 'GCAARNrds',
  Ramco = 'Ramco',
  Orea = 'Orea',
  FilesUser = 'FilesUser',
  AccessCode = 'AccessCode',
  CtNrds = 'CtNrds',
  RiNrds = 'RiNrds',
  NcNrds = 'NcNrds',
  EctNrds = 'EctNrds',
  SctNrds = 'SctNrds',
  ScNrds = 'ScNrds',
  VaNrds = 'VaNrds',
  FlNrds = 'FlNrds',
  LaNrds = 'LaNrds',
  UtNrds = 'UtNrds',
  CanopyNcNrds = 'CanopyNcNrds',
  BEARCaNrds = 'BEARCaNrds',
  MoNrds = 'MoNrds',
  GBREBNrds = 'GBREBNrds',
  ChicagoNrds = 'ChicagoNrds',
  Ca0808Nrds = 'Ca0808Nrds',
  PrinceGeorgeNrds = 'PrinceGeorgeNrds',
  KansasCityNrds = 'KansasCityNrds',
  RSARNrds = 'RSARNrds',
}

// This should be kept upto date with files-ui
// https://bitbucket.org/skyslope/files-ui/src/master/src/store/types.ts
export const NRDS_AUTH_PROFILE_TYPES: { [key in AuthType]?: boolean } = {
  [AuthType.AzNrds]: true,
  [AuthType.TxNrds]: true,
  [AuthType.MdNrds]: true,
  [AuthType.OhNrds]: true,
  [AuthType.InNrds]: true,
  [AuthType.MaNrds]: true,
  [AuthType.PaNrds]: true,
  [AuthType.NSBARNrds]: true,
  [AuthType.GCAARNrds]: true,
  [AuthType.CtNrds]: true,
  [AuthType.RiNrds]: true,
  [AuthType.NcNrds]: true,
  [AuthType.EctNrds]: true,
  [AuthType.SctNrds]: true,
  [AuthType.ScNrds]: true,
  [AuthType.VaNrds]: true,
  [AuthType.FlNrds]: true,
  [AuthType.LaNrds]: true,
  [AuthType.UtNrds]: true,
  [AuthType.CanopyNcNrds]: true,
  [AuthType.BEARCaNrds]: true,
  [AuthType.MoNrds]: true,
  [AuthType.GBREBNrds]: true,
  [AuthType.ChicagoNrds]: true,
  [AuthType.Ca0808Nrds]: true,
  [AuthType.PrinceGeorgeNrds]: true,
  [AuthType.KansasCityNrds]: true,
  [AuthType.RSARNrds]: true,
};

// ENV specific constants
export const ENV = (() => {
  if (
    window.location.host.includes('integ') ||
    window.location.host.includes('localhost') ||
    window.location.host.includes('ngrok')
  ) {
    return appEnvironments.INTEG;
  } else if (window.location.host.includes('staging')) {
    return appEnvironments.STAGING;
  } else {
    return appEnvironments.PROD;
  }
})();

const configByEnv = {
  [appEnvironments.INTEG]: {
    env: appEnvironments.INTEG,
    sklosuresServiceUrl: `https://integ-sklosures.skyslope.com/api`,
    breezeServiceUrl: process.env.BREEZE_API_URL ?? 'https://integ-breeze.skyslope.com/api',
    mlsServiceUrl: 'https://integ-mls-service.skyslope.com',
    skyslopeAppUrl: 'https://integ.skyslope.com',
    formsApiUrl: 'https://integ-forms.skyslope.com',
    ds3UserServiceUrl: 'https://integ-digisign3.skyslope.com/api',
    ds3SignerUrl: 'https://integ-sign.skyslope.com',
    accountsUrl: 'https://accounts-integ.skyslope.com',
    skyslopeBooksUrl: 'https://zipi-dev.skyslope.com/default-page',
    openWeatherApiUrl: 'https://api.openweathermap.org/data/2.5/weather',
    openWeatherAppId: '3a262515b021d1ec049240bb01d2caf3',
    auth: {
      okta: {
        baseUrl: 'https://id-integ.skyslope.com',
        clientId: '0oa88jp4ggVEHdaIa357',
        formsServer: 'aus1589ztbT5gAqTt357',
        primeIdp: '0oa154s1ybu3Lbyo6357',
        requestContext: '/home/oidc_client/0oa88jp4ggVEHdaIa357/aln177a159h7Zf52X0g8',
      },
      primeAuth: {
        clientId: 'C6T8gzFlFyyrs4ufWV7L',
      },
    },
    heapId: 355210786,
    chameleonToken: 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB',
    launchDarklyId: '60a2b6d9e362b10dc1040b4f',
  },
  [appEnvironments.STAGING]: {
    env: appEnvironments.STAGING,
    sklosuresServiceUrl: `https://staging-sklosures.skyslope.com/api`,
    breezeServiceUrl: 'https://staging-breeze.skyslope.com/api',
    mlsServiceUrl: 'https://staging-mls-service.skyslope.com',
    skyslopeAppUrl: 'https://staging.skyslope.com',
    formsApiUrl: 'https://staging-forms.skyslope.com',
    ds3UserServiceUrl: 'https://staging-digisign3.skyslope.com/api',
    ds3SignerUrl: 'https://staging-sign.skyslope.com',
    accountsUrl: 'https://accounts-staging.skyslope.com',
    skyslopeBooksUrl: 'https://zipi-dev.skyslope.com/default-page',
    openWeatherApiUrl: 'https://api.openweathermap.org/data/2.5/weather',
    openWeatherAppId: '3a262515b021d1ec049240bb01d2caf3',
    auth: {
      okta: {
        baseUrl: 'https://id-staging.skyslope.com',
        clientId: '0oaxqcnwbwbeUW2ua0h7',
        formsServer: 'auspx3z6bi3weJs200h7',
        primeIdp: '0oapx40qvxeQeNory0h7',
        requestContext: '/home/oidc_client/0oaxqcnwbwbeUW2ua0h7/aln5z7uhkbM6y7bMy0g7',
      },
      primeAuth: {
        clientId: '9ykvNbS6rvAE3newqP45',
      },
    },
    heapId: 1691345378,
    chameleonToken: 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB',
    launchDarklyId: '60a2b6e1a3ccc20d64918552',
  },
  [appEnvironments.PROD]: {
    env: appEnvironments.PROD,
    sklosuresServiceUrl: `https://sklosures.skyslope.com/api`,
    breezeServiceUrl: 'https://breeze.skyslope.com/api',
    mlsServiceUrl: 'https://mls-service.skyslope.com',
    skyslopeAppUrl: 'https://app.skyslope.com',
    formsApiUrl: 'https://forms.skyslope.com',
    ds3UserServiceUrl: 'https://digisign3.skyslope.com/api',
    ds3SignerUrl: 'https://sign.skyslope.com',
    accountsUrl: 'https://accounts.skyslope.com',
    skyslopeBooksUrl: 'https://books.skyslope.com/default-page',
    openWeatherApiUrl: 'https://api.openweathermap.org/data/2.5/weather',
    openWeatherAppId: '3a262515b021d1ec049240bb01d2caf3',
    auth: {
      okta: {
        baseUrl: 'https://id.skyslope.com',
        clientId: '0oa2l7o3k55BlHsjW4x7',
        formsServer: 'aus25q3otn0cUfwED4x6',
        primeIdp: '0oa25qgk2HCoSakYh4x6',
        requestContext: '/home/oidc_client/0oa2l7o3k55BlHsjW4x7/aln177a159h7Zf52X0g8',
      },
      primeAuth: {
        clientId: 'TYdjzzNyEB6CyiqaP3z1',
      },
    },
    heapId: 3954488189,
    chameleonToken: 'SIMVvFC0cW5aRmXMWnBWuSrsLp6oY3utpm8ZD5ciik5ByF-1H74NM-Bgha6VrqZM0nduVB',
    launchDarklyId: '60a2b69852b75e0db16dbf61',
  },
};

export const routes = {
  landing: '/',
  logout: '/logout',
  login: '/login/:stepName?',
  loggedOut: '/logged_out',
  register: '/register/:stepName?',
  unauthorized: '/unauthorized',
  authCallback: '/auth/callback',
  primeAuthCallback: '/auth/callback/prime',
  about: '/about',
  contact: '/contact',
  disclosuresStart: '/disclosures',
  disclosures: '/disclosures/:stepName?',
  resendDisclosures: '/resend-disclosures/:fileId/packages/:packageId/:stepName?',
  sendForms: `/send-forms/:packageId/:stepName?`,
  avidCreationStart: '/create-avid',
  avidCreation: '/create-avid/:stepName?',
  avidDetails: '/avid/:packageId/:stepName?',
  dashboard: '/dashboard',
  team: '/team',
  filePackages: '/file/:fileId/packages',
  settings: '/settings',
};

export const landingRoutes = {
  landing: '/',
  humboldt: '/humboldt',
  lawyers: '/lawyers',
  ticor: '/ticor',
  chicago: '/chicago',
  fidelity: '/fidelity',
  grandcanyon: '/grandcanyon',
  security: '/security',
  chicagofidelity: '/chicagofidelity',
  western: '/western',
  navi: '/navi',
  alamo: '/alamo',
  austin: '/austin',
  charter: '/charter',
  startex: '/startex',
  westtexas: '/westtexas',
  cascade: '/cascade',
};

const createDefaultWizardRoute = (route: string) =>
  route.slice(
    0,
    findLastIndex(route, (c) => c === '/')
  );

export const defaultWizardRoutes = {
  login: createDefaultWizardRoute(routes.login),
  disclosures: createDefaultWizardRoute(routes.disclosures),
  avidCreation: createDefaultWizardRoute(routes.avidCreation),
  avidDetails: createDefaultWizardRoute(routes.avidDetails),
  sendForms: createDefaultWizardRoute(routes.sendForms),
  resendDisclosures: createDefaultWizardRoute(routes.resendDisclosures),
};

export enum LocalStorageItem {
  ReduxDebugging = 'reduxDebugging',
  IsLastLoggedInAsPrimeAuthUser = 'auth.is-last-logged-in-as-prime-auth-user',
  CarLastPopUnder = 'CAR-last-popunder',
  DeviceLastAuthenticated = 'device.lastAuthenticated',
}

export enum SessionStorageItem {
  LoginOGPath = 'breeze.login.og.path',
}

export const config = configByEnv[ENV];

export const getCountiesByState = (state: string): [string] => stateCounties[state] || [];

export enum usStates {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
}

export const stateLandingRoutes = {
  alabama: '/Alabama',
  alaska: '/Alaska',
  arizona: '/Arizona',
  arkansas: '/Arkansas',
  california: '/California',
  colorado: '/Colorado',
  connecticut: '/Connecticut',
  delaware: '/Delaware',
  districOfColumbia: '/DistrictOfColumbia',
  florida: '/Florida',
  georgia: '/Georgia',
  hawaii: '/Hawaii',
  idaho: '/Idaho',
  illinois: '/Illinois',
  indiana: '/Indiana',
  iowa: '/Iowa',
  kansas: '/Kansas',
  kentucky: '/Kentucky',
  louisiana: '/Louisiana',
  maine: '/Maine',
  maryland: '/Maryland',
  massachusetts: '/Massachusetts',
  michigan: '/Michigan',
  minnesota: '/Minnesota',
  mississippi: '/Mississippi',
  missouri: '/Missouri',
  montana: '/Montana',
  nebraska: '/Nebraska',
  nevada: '/Nevada',
  newHampshire: '/NewHampshire',
  newJersey: '/NewJersey',
  newMexico: '/NewMexico',
  newYork: '/NewYork',
  northCarolina: '/NorthCarolina',
  northDakota: '/NorthDakota',
  ohio: '/Ohio',
  oklahoma: '/Oklahoma',
  oregon: '/Oregon',
  pennsylvania: '/Pennsylvania',
  rhodeIsland: '/RhodeIsland',
  southCarolina: '/SouthCarolina',
  southDakota: '/SouthDakota',
  tennessee: '/Tennessee',
  texas: '/Texas',
  utah: '/Utah',
  vermont: '/Vermont',
  virginia: '/Virginia',
  washington: '/Washington',
  westVirginia: '/WestVirginia',
  wisconsin: '/Wisconsin',
  wyoming: '/Wyoming',
};

export interface AuthTypeInfo {
  authType: AuthType;
  libraryName: string;
  libraryIds: number[];
  region: usStates;
}

const authTypeToInfo = {
  [AuthType.AzNrds]: {
    libraryName: 'A.A.R.',
    region: usStates.AZ,
    libraries: {
      [appEnvironments.INTEG]: [1550],
      [appEnvironments.STAGING]: [6],
      [appEnvironments.PROD]: [6],
    },
  },
  [AuthType.TxNrds]: {
    libraryName: 'T.X.R.',
    region: usStates.TX,
    libraries: {
      [appEnvironments.INTEG]: [9142],
      [appEnvironments.STAGING]: [31],
      [appEnvironments.PROD]: [1328],
    },
  },
  [AuthType.NWMLS]: {
    libraryName: 'N.W.M.L.S.',
    region: usStates.WA,
    libraries: {
      [appEnvironments.INTEG]: [9143],
      [appEnvironments.STAGING]: [30],
      [appEnvironments.PROD]: [1337],
    },
  },
  [AuthType.Oref]: {
    libraryName: 'O.R.E.F.',
    region: usStates.OR,
    libraries: {
      [appEnvironments.INTEG]: [9144],
      [appEnvironments.STAGING]: [29],
      [appEnvironments.PROD]: [1340],
    },
  },
  [AuthType.CaNrds]: {
    libraryName: 'C.A.R.',
    region: usStates.CA,
    libraries: {
      [appEnvironments.INTEG]: [14289],
      [appEnvironments.STAGING]: [40602],
      [appEnvironments.PROD]: [1360],
    },
  },
  [AuthType.Ca0808Nrds]: {
    libraryName: 'S.F.A.R.',
    region: usStates.CA,
    libraries: {
      [appEnvironments.INTEG]: [36571],
      [appEnvironments.STAGING]: [66214],
      [appEnvironments.PROD]: [1688],
    },
  },
  [AuthType.RSARNrds]: {
    libraryName: 'R.S.A.R.',
    region: usStates.NV,
    libraries: {
      [appEnvironments.INTEG]: [36029],
      [appEnvironments.STAGING]: [63834],
      [appEnvironments.PROD]: [1495],
    },
  },
};

export const getAuthTypeInfo = (authType?: AuthType): AuthTypeInfo | undefined => {
  if (authType) {
    return {
      authType,
      ...authTypeToInfo[authType],
      libraryIds: authTypeToInfo[authType].libraries[ENV],
    };
  }
  return;
};

export const downloadTypes = {
  ZIP: 'zip',
  MERGED: 'merged',
};

export enum TdsSect3CheckboxMapping {
  ATTACH_AVID = 'tdsHasAttachedAvidSection3',
  AGENT_NO_NOTES = 'tdsAgentNotesNoItemsSection3',
  AGENT_HAS_NOTES = 'tdsAgentNotesItemsSection3',
}

export enum PackageTypes {
  avid = 'avid',
  disclosures = 'disclosures',
}

export interface EnvironmentConfig {
  dev?: string;
  integ: string;
  staging: string;
  prod: string;
}

export const gtmAuth = {
  [appEnvironments.INTEG]: 'AC2GoMnqdtM_fTnRg-Fg_g',
  [appEnvironments.STAGING]: '9OVXeDhfb1ILj5G0lpxTtw',
  [appEnvironments.PROD]: '8WAA4RAALtwpjBysEvBZig',
};

export const gtmPreview = {
  [appEnvironments.INTEG]: 'env-5',
  [appEnvironments.STAGING]: 'env-4',
  [appEnvironments.PROD]: 'env-1',
};

export const GOOGLE_TAG_MANAGER_ID = 'GTM-56H38PR';
export const GOOGLE_TAG_MANAGER_AUTH = gtmAuth[ENV];
export const GOOGLE_TAG_MANAGER_PREVIEW = gtmPreview[ENV];

export enum TemplateStatus {
  LIVE = 'LIVE',
  NEW = 'NEW',
  RETIRED = 'RETIRED',
  TEST = 'TEST',
}

export const GOOGLE_PLACES_API_KEY = 'AIzaSyBNpJPeQxtt6VScZyh91wD7uP_qj7QjvfM';

export const PAGINATION_PACKAGES_PER_PAGE = 25;
