import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { getGraphQLClient, getTokens } from '../../lib';
import { useGetUserPermissionsRequestsKey, UserPermissionRequestData } from './useGetUserPermissionRequests';

export interface CancelInvitePayload {
  delegateFromIds: string[];
}

export interface CancelInviteResult {
  delegateId: string;
  fullAccessDelegateRequests: UserDelegateWithMetadata[];
  createdAt: string;
  updatedAt: string;
}

export interface UserDelegateWithMetadata {
  ownerId: string;
  isApproved: boolean;
  isRevoked: boolean;
  createdAt: string;
  updatedAt: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
}

const mutationQuery = gql`
  mutation cancelFullAccessDelegates($delegateFromIds: [String]!) {
    cancelFullAccessDelegates(delegateFromIds: $delegateFromIds) {
      delegateId
    }
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
  cancelledInvite: CancelInvitePayload;
}

export const cancelInviteAccessKey = 'cancel-invite-mutation';

/**
 * This hook allows a TC to cancel an invite that was sent by that TC to an agent.
 * This hook is only used by TCs, never by Agents.
 */
export const useCancelInvite = (
  options: UseMutationOptions<CancelInviteResult, AxiosError, CancelInvitePayload> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<CancelInviteResult, AxiosError, CancelInvitePayload>(
    async (payload: CancelInvitePayload) => {
      const client = getGraphQLClient(await getTokens());
      return (await client.request<{ res: CancelInviteResult }, CancelInvitePayload>(mutationQuery, payload)).res;
    },
    {
      mutationKey: cancelInviteAccessKey,
      // When mutate is called:
      async onMutate(payload: CancelInvitePayload): Promise<MutationContext> {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        // Snapshot the previous value
        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        // Optimistically update to the new value
        queryClient.setQueryData(
          useGetUserPermissionsRequestsKey,
          (userPermissionRequest: UserPermissionRequestData) => {
            const delegateIdsToCancel = payload.delegateFromIds;

            return {
              ...userPermissionRequest,
              delegatedFromUserRequests: userPermissionRequest.delegatedFromUserRequests.filter(
                (delegatingAgent) => !delegateIdsToCancel.includes(delegatingAgent.ownerId)
              ),
            };
          }
        );

        // Return a context object with the snapshotted value
        return { previousInvites, cancelledInvite: payload };
      },
      // If the mutation fails, use the context we returned above
      onError(e, variables, context: MutationContext) {
        console.error('An error occurred while cancelling invite');
        Sentry.captureException(e);
        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        options?.onError?.(e, variables, context);
      },
      // Always refetch after error or success:
      onSettled() {
        queryClient.invalidateQueries(useGetUserPermissionsRequestsKey);
      },
      ...options,
    }
  );
};
