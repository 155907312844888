import { UseMutationOptions, useMutation } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getTokens } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';

interface DownloadPackageVariables {
  packageId: string;
}
export interface DownloadPackageResponse {
  filename: string;
  content: string;
}

export const downloadMergedPackageKey = 'download-package-merged';

const query = gql`
  query downloadPackage($packageId: String) {
    packageCombinedPDFsBase64(packageId: $packageId) {
      filename
      content
    }
  }
`;

export const useDownloadPackageMerged = (
  options: UseMutationOptions<unknown, unknown, DownloadPackageVariables> = {}
) => {
  const snackbarController = useCustomSnackbar();

  return useMutation(
    async (variables: DownloadPackageVariables) => {
      const client = getGraphQLClient(await getTokens());
      return (await client.request<{ packageCombinedPDFsBase64: DownloadPackageResponse }>(query, variables))
        .packageCombinedPDFsBase64;
    },
    {
      ...options,
      onMutate() {
        snackbarController.addInfoSnackbar('Downloading your files');
      },
      onError(e, variables, context) {
        console.error(e);
        const eventId = Sentry.captureException(e);

        if (options.onError) {
          options.onError(e, variables, context);
        } else {
          snackbarController.addErrorSnackbar({ errorCode: ErrorCodes.DownloadPackageMerged, eventId });
        }
      },
    }
  );
};
