import { TokenDomain } from '@skyslope/auth-js';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions } from 'react-query';
import { getTokens, getGraphQLClient } from '../../lib';

export enum EmailType {
  GRANT = 'grant',
  EXISTING_USER_REQUEST = 'existing-user-request',
  NEW_USER_REQUEST = 'new-user-request',
  DECLINED = 'declined',
  REMINDER = 'reminder',
  REQUEST_TITLE = 'request-title',
}

export interface AccessEmailPayload {
  delegate: {
    firstName?: string;
    email: string;
  };
  emailType: EmailType;
}

export interface UseSendAccessEmailResponse {
  response: string;
}

const mutationQuery = gql`
  mutation SendAccessEmail($payload: AccessEmailPayload) {
    sendAccessEmail(payload: $payload)
  }
`;

export const sendEmailAccessKey = 'send-email-access-mutation';

export const useSendAccessEmail = (
  options: UseMutationOptions<UseSendAccessEmailResponse, AxiosError, AccessEmailPayload> = {}
) =>
  useMutation<UseSendAccessEmailResponse, AxiosError, AccessEmailPayload>(
    async (payload: AccessEmailPayload) => {
      const client = getGraphQLClient(await getTokens([TokenDomain.Forms, TokenDomain.Accounts]));
      return (
        await client.request<{ data: UseSendAccessEmailResponse }, { payload: AccessEmailPayload }>(mutationQuery, {
          payload,
        })
      ).data;
    },
    {
      mutationKey: sendEmailAccessKey,
      ...options,
    }
  );
