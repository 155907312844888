import { useEffect } from 'react';
import { useUserClaims } from '@skyslope/auth-react';
import { ENV } from 'src/lib';

export const useSetupClarity = (): void => {
  const { claims } = useUserClaims();

  const setClarity = (attribute: string, value: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clarity('set', attribute, value);
  };

  useEffect(() => {
    if (claims?.sub) {
      setClarity('environment', ENV);
      setClarity('email', claims.email);
      setClarity('sub', claims.sub);
      setClarity('subscriber-id', claims.subscriber_id);
    }
  }, [claims]);
};
