import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import { getGraphQLClient, getTokens } from '../../../lib';

const query = gql`
  {
    avidRoomListItems {
      displayName
      roomType
      max
    }
  }
`;

export interface AvidRoomOption {
  roomType: string;
  displayName: string;
  max: number;
}

export const useGetAvidRoomOptions = () =>
  useQuery('avid-room-list', async () => {
    const client = getGraphQLClient(await getTokens());
    return (await client.request<{ avidRoomListItems: AvidRoomOption[] }, undefined>(query)).avidRoomListItems;
  });
