import { useUserClaims } from '@skyslope/auth-react';
import { useEffect } from 'react';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

export const useSetupLaunchDarkly = () => {
  const ldClient = useLDClient();
  const { claims } = useUserClaims();
  const isOnPeadUrl = window.location.hostname.includes('peady');
  const { testFlag } = useFlags();

  useEffect(() => {
    if (claims) {
      ldClient?.identify({
        key: claims.sub ?? 'anonymous',
        lastName: claims.last_name,
        email: claims.email,
        custom: {
          isOnPeadUrl,
        },
      });
    } else {
      ldClient?.identify({
        key: 'anonymous',
        custom: {
          isOnPeadUrl,
        },
      });
    }
  }, [claims, isOnPeadUrl, ldClient]);

  useEffect(() => {
    if (testFlag) {
      // eslint-disable-next-line no-console
      console.debug('test flag enabled');
    }
  }, [testFlag]);
};
