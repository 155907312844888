import { gql } from 'graphql-request';
import { useQuery, UseQueryOptions } from 'react-query';
import { GraphQLError } from 'graphql';
import { TokenDomain } from '@skyslope/auth-js';
import { getGraphQLClient, getTokens, UserMetaRole } from '../../../lib';

export interface BreezeUserMeta {
  role: UserMetaRole;
}

interface QueryResult {
  getUserMeta?: BreezeUserMeta;
}

export const getUserMetaKey = 'get-user-meta-key';

const query = gql`
  query userMeta {
    getUserMeta {
      role
    }
  }
`;

export const useGetUserMeta = <SelectedResult = BreezeUserMeta | undefined>(
  options: UseQueryOptions<BreezeUserMeta | undefined, GraphQLError, SelectedResult> = {}
) =>
  useQuery<BreezeUserMeta | undefined, GraphQLError, SelectedResult>(
    getUserMetaKey,
    async () => {
      const client = getGraphQLClient(await getTokens([TokenDomain.Forms]));
      const result = await client.request<QueryResult>(query);
      return result.getUserMeta;
    },
    {
      ...options,
      staleTime: Infinity, // since this data is immutable currently we don't need to re-fetch this unless invalidated by useCreateUserMeta
    }
  );
